import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';
import { IProjectSettings, getDefaultProjectSettings } from '@/models/core/IProjectSettings';

const { persistAtom } = recoilPersist();

// PROJECT SETTINGS
const stateKeyProjectSettings = 'stateKeyProjectSettings';
const stateProjectSettings = atom<IProjectSettings>({
  key: stateKeyProjectSettings,
  default: getDefaultProjectSettings(),
  effects_UNSTABLE: [persistAtom],
});

export { stateProjectSettings };
