import React, { useState, useCallback, useEffect } from 'react';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { StateManagerContext } from '@/components/react_wrappers/state_manager/context';
import { ProjectSettingsApi } from '@/services/django-rest-services/core/project_settings_api';
import { UsersApi } from '@/services/django-rest-services/users/users_api';
import { stateToken, stateUser } from '@/states/auth';
import { stateProjectSettings } from '@/states/core';
import { cleanUrlParam } from 'routes';

/**
 * STATES LIST
 *
 * You'll want to add all states here as you create and add them to the app.
 */
export const statesList = [stateToken, stateUser];

/**
 * PROPS
 *
 * @param {React.ReactNode} children - children component
 */
interface IPropsStateManager {
  children: React.ReactNode;
}

/**
 * STATE MANAGER
 *
 * This is the main state manager for the app. It's a wrapper for all the states
 * and functions that update and clear them. We currently use Recoil for state management.
 *
 * To interact with the state manager, you'll want to use the context like so:
 *
 *     stateManagerContext.updateStateFunctions.updateUserData();
 *
 * or whatever function you want to call.
 */
export default function StateManager(Props: IPropsStateManager): React.ReactElement {
  const [, setProjectSettings] = useRecoilState(stateProjectSettings);
  const [user, setUser] = useRecoilState(stateUser);
  const [token] = useRecoilState(stateToken);
  const { children } = Props;

  const clearToken = useResetRecoilState(stateToken);
  const clearUser = useResetRecoilState(stateUser);
  const clearAllStates = useCallback(() => {
    clearToken();
    clearUser();
  }, [clearToken, clearUser]);
  const clearStateFunctions = {
    clearAllStates,
    clearToken,
    clearUser,
  };

  // update user data
  const updateUserData = useCallback(() => {
    const callUserDetails = async () => {
      const usersApi = new UsersApi(token!);
      const res = await usersApi.get.getRetrieve(cleanUrlParam(user!.id));
      if (!res.error && !!res.obj && !(res.obj instanceof Array)) {
        setUser(res.obj);
      }
      if (res.response.status === 401 || res.message === 'Invalid token.') {
        clearAllStates();
      }
    };

    if (!!user && !!token?.length) {
      void callUserDetails();
    }
  }, [user, token, setUser, clearAllStates]);

  const [lastProjectSettingsCall, setLastProjectSettingsCall] = useState<number>(0);

  // update project settings
  const updateProjectSettings = useCallback(() => {
    const callProjectSettings = async () => {
      const projectSettingsApi = new ProjectSettingsApi(token!);
      // NOTE this is a special instance where we don't pass an ID
      // because there is only one project settings object per project
      const res = await projectSettingsApi.get.getRetrieve('');
      if (!res.error && !!res.obj && !(res.obj instanceof Array)) {
        const newProjectSettings = {
          ...res.obj,
          isDefaultSettings: false,
        };
        setProjectSettings(newProjectSettings);
      }
      if (res.response.status === 401 || res.message === 'Invalid token.') {
        clearAllStates();
      }
    };

    const now = Date.now();
    const sixtySeconds = 60000;

    // Check if 60 seconds have passed since the last call
    if (now - lastProjectSettingsCall < sixtySeconds) {
      return;
    }

    setLastProjectSettingsCall(now);

    // if we don't have project settings, get them
    void callProjectSettings();
  }, [token, setProjectSettings, clearAllStates, lastProjectSettingsCall]);

  const updateStateFunctions = {
    updateUserData,
    updateProjectSettings,
  };

  useEffect(() => {
    // Call updateProjectSettings immediately on component mount
    updateProjectSettings();
    // Set up the timer to call updateProjectSettings every 60 seconds
    const interval = setInterval(updateProjectSettings, 60000);
    // Clear the interval on component unmount
    return () => clearInterval(interval);
  }, [updateProjectSettings]);

  /**
   * RENDER
   */
  return <StateManagerContext.Provider value={{ updateStateFunctions, clearStateFunctions }}>{children}</StateManagerContext.Provider>;
}
