import getConfig from 'next/config';

export type TEnvironmentVariableVisibility = 'server' | 'both';

function getEnvironmentVariableOptional(environmentVariable: string, defaultVal?: string): string | undefined {
  try {
    return getEnvironmentVariable(environmentVariable);
  } catch (e) {
    return defaultVal ?? undefined;
  }
}

function getEnvironmentVariable(environmentVariable: string, visibility: TEnvironmentVariableVisibility = 'both'): string {
  const val = getConfig().publicRuntimeConfig[environmentVariable];
  if (val) {
    return val;
  } else {
    if (visibility === 'server') {
      return getConfig().serverRuntimeConfig[environmentVariable];
    }
    throw new Error(`Environment variable: ${environmentVariable} is required.`);
  }
}

/**
 * Environment
 *
 * Require and store environment variables for access throughout
 * the application. Be sure to declare any and all environment
 * variables you want here as well as in 'next.config.js'
 *
 * NOTE: the usage of different functions if vars are required or not
 */
export const Environment = {
  uriApi: getEnvironmentVariable('URI_API'),
  uriSite: getEnvironmentVariable('URI_SITE'),
  sentryDsn: getEnvironmentVariableOptional('SENTRY_DSN'),
  // add front end env variables here and in next.config.js
};
