import { setUser, setContext, addEventProcessor } from '@sentry/nextjs';
import { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { stateUser } from '@/states/auth';
import { stateProjectSettings } from '@/states/core';
import type React from 'react';
import { Environment } from 'environment';

const ENABLED_SENTRY_ENVIRONMENTS = ['production', 'staging'];

/**
 * PROPS
 *
 * @param {React.ReactNode} children - children component
 */
interface IPropsSentryManager {
  children: React.ReactElement;
}

/**
 * SENTRY MANAGER
 * This is the main Sentry manager for the app.
 * It initializes Sentry and sets the user and project context.
 */
export function SentryManager(Props: IPropsSentryManager): React.ReactElement {
  const { children } = Props;

  // Recoil States
  const [user] = useRecoilState(stateUser);
  const [projectSettings] = useRecoilState(stateProjectSettings);

  // Local States
  const [enabledSentry, setEnabledSentry] = useState(false);
  const [loading, setLoading] = useState(true); // Add loading state

  // Set Sentry User and Project Context
  useEffect(() => {
    if (enabledSentry === false) return;
    if (user) {
      setUser({
        email: user.email,
        id: user.id,
      });
    } else {
      setUser(null);
    }
  }, [user, enabledSentry]);

  // Set Sentry Project settings
  useEffect(() => {
    if (enabledSentry === false) return;
    if (projectSettings.isDefaultSettings === false) {
      setContext('project', {
        ...projectSettings,
      });
    }
  }, [projectSettings, enabledSentry]);

  // set whether Sentry is enabled
  useEffect(() => {
    if (projectSettings.isDefaultSettings) {
      setLoading(false);
      return;
    }
    if (!Environment.sentryDsn || !Environment.sentryDsn.length) {
      setLoading(false);
      return;
    }
    if (!ENABLED_SENTRY_ENVIRONMENTS.includes(projectSettings.environment.toLowerCase())) {
      setLoading(false);
      return;
    }
    setEnabledSentry(true);
    setLoading(false);
  }, [projectSettings]);

  // Add Before Send hook based on Sentry enabled state
  useEffect(() => {
    if (loading) return;
    addEventProcessor((event) => {
      if (!enabledSentry) {
        // If Sentry is disabled, prevent the event from being sent
        return null;
      }
      // Otherwise, return the event unchanged
      return event;
    });
  }, [enabledSentry, loading]);

  return children;
}
