import { Environment } from 'environment';
import DjangoGet from '@/services/typescript-api-services/src/api/django_service/methods/get';
import { IProjectSettings } from '@/models/core/IProjectSettings';

/**
 * PROJECT SETTINGS API
 */
export class ProjectSettingsApi {
  name = 'Project Settings API';
  uriApi = Environment.uriApi;
  uriPath = 'core/project-settings';
  token?: string;

  // API METHODS
  get: DjangoGet<IProjectSettings>;

  // CONSTRUCTOR
  constructor(token?: string) {
    this.token = token;
    this.get = new DjangoGet<IProjectSettings>(this.name, this.uriApi, this.uriPath, this.token);
  }
}
