import * as Sentry from '@sentry/react';
import { ThemeProvider } from 'next-themes';
import React from 'react';
import '@/styles/globals.scss';
import { RecoilRoot } from 'recoil';
import FallbackComponent from '@/components/react_wrappers/fallback_component';
import { SentryManager } from '@/components/react_wrappers/sentry_manager';
import { SettingsManager } from '@/components/react_wrappers/settings_manager';
import StateManager from '@/components/react_wrappers/state_manager';
import useHasMounted from '@/utils/hooks/UseHasMounted';
import type { AppProps } from 'next/app';

function MyApp({ Component, pageProps }: AppProps) {
  const hasMounted = useHasMounted();
  if (!hasMounted) {
    return null;
  }

  return (
    <Sentry.ErrorBoundary fallback={FallbackComponent}>
      <RecoilRoot>
        <StateManager>
          <SettingsManager>
            <SentryManager>
              <ThemeProvider>
                <Component {...pageProps} />
              </ThemeProvider>
            </SentryManager>
          </SettingsManager>
        </StateManager>
      </RecoilRoot>
    </Sentry.ErrorBoundary>
  );
}

export default MyApp;
