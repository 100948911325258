import { createContext, useContext } from 'react';

/**
 * STATE MANAGER CONTEXT
 */
export interface IStateManagerContext {
  updateStateFunctions: {
    updateUserData: () => void;
    updateProjectSettings: () => void;
  };
  clearStateFunctions: {
    clearAllStates: () => void;
    clearToken: () => void;
    clearUser: () => void;
  };
}

// Context object
export const StateManagerContext = createContext<IStateManagerContext>({
  updateStateFunctions: {
    updateUserData: () => {},
    updateProjectSettings: () => {},
  },
  clearStateFunctions: {
    clearAllStates: () => {},
    clearToken: () => {},
    clearUser: () => {},
  },
});

// Context consumer hook
export const useStateManagerContext = () => {
  // get the context
  const context = useContext(StateManagerContext);

  if (typeof context === 'undefined') {
    throw new Error('useStateManagerContext was used outside of its Provider');
  }

  return context;
};
