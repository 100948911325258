import React from 'react';
import BBButton from '@/base_blocks/src/bbbutton';
import BBText from '@/base_blocks/src/bbtext';
import styles from '@/components/react_wrappers/fallback_component/styles.module.scss';
import Routes from 'routes';

/**
 * FALLBACK COMPONENT
 */
export default function FallbackComponent(): React.ReactElement {
  const onClickHome = () => {
    window.location.href = Routes.routeHome();
  };

  const onClickBack = () => {
    window.history.back();
  };

  /**
   * RENDER
   */
  return (
    <div className={styles.containerFallbackComponent}>
      <BBText size="xxxlarge">Sorry about that!</BBText>
      <BBText size="xlarge">Something went wrong...</BBText>
      <div className={styles.containerButtons}>
        <BBButton variant="primary" onClick={onClickHome} size="lg" text="Go Home" />
        <BBButton variant="secondary" onClick={onClickBack} size="lg" text="Go Back" />
      </div>
    </div>
  );
}
