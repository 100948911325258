/// ================================================================= /
/// PROJECT SETTINGS ================================================ /
/// ================================================================= /

import { TBBTextColor } from '@/base_blocks/src/types';

export type TProjectSettingsEnvironment = 'development' | 'staging' | 'production' | 'unavailable';
export interface IProjectSettings {
  name: string;
  description: string;
  environment: TProjectSettingsEnvironment;
  version: string;
  // isDefaultSettings is used to determine if the project settings are the default settings
  // or if they have been updated via the API
  isDefaultSettings?: boolean;
}

export const getDefaultProjectSettings = (): IProjectSettings => {
  return {
    name: 'Unavailable',
    description: 'Unavailable',
    environment: 'unavailable',
    version: 'Unavailable',
    isDefaultSettings: true,
  };
};

export const getProjectSettingsEnvTextColor = (env: TProjectSettingsEnvironment): TBBTextColor => {
  switch (env) {
    case 'development':
      return 'primary';
    case 'staging':
      return 'accent';
    case 'production':
      return 'secondary';
    default:
      return 'grey_light';
  }
};
