/**
 * ROUTES
 *
 * Declare your routes in this class. Not required to use but helpful for organization
 * Add a method for your route, with or without parameters, then call it like:
 *
 *   await router.push(Routes.routeHome())
 *
 * Trailing '/' are preferred :)
 */
import { Environment } from 'environment';

export default class Routes {
  static routeHome() {
    return '/';
  }

  static routeAbout() {
    return '/about/';
  }

  static routeContact() {
    return '/contact/';
  }

  static routePricing() {
    return '/pricing/';
  }

  static routeFaq() {
    return '/faq/';
  }

  static routeLogin() {
    return '/login/';
  }

  static routeSignup() {
    return '/signup/';
  }

  static routePasswordResetRequest() {
    return '/password-reset/request/';
  }

  static routePasswordResetChange(userToken: string, userId: string) {
    return `/password-reset/change/${userToken}/${userId}/`;
  }

  // PUBLIC SEARCH ROUTES

  static routeSearchDetails(searchId: string) {
    return `/searches/${searchId}/`;
  }

  static routeSearchResultDetails(searchResultId: string) {
    return `/searches/results/${searchResultId}/`;
  }

  static routeTagResults(tagId: string) {
    return `/tags/${tagId}/`;
  }

  // SERVER ROUTES
  static routeAdmin() {
    return `${Environment.uriApi}/management/`;
  }

  static routeApiDocs() {
    return `${Environment.uriApi}/api/docs/`;
  }

  // LEGAL
  static routeLegalTOS() {
    return '/legal/tos/';
  }
  static routeLegalPrivacyPolicy() {
    return '/legal/privacy-policy/';
  }

  /**
   * USER PORTAL
   */
  static routeUserDashboard(userId: string) {
    return `/users/${userId}/`;
  }

  static routeUserAccount(userId: string) {
    return `/users/${userId}/account/`;
  }

  static routeUserSupport(userId: string) {
    return `/users/${userId}/support/`;
  }

  // SCRAPY
  static routeUserSearches(userId: string) {
    return `/users/${userId}/searches/`;
  }
}

export const cleanUrlParam = (input: string | string[] | undefined | null): string => {
  /**
   * Clean up a URL parameters given from NextJS
   * NextJS gives URL params as a `string[] | string | undefined`
   * use this to easily convert to a string
   *
   * @param input - the URL parameter to clean up
   */
  if (typeof input === 'undefined' || input === null) {
    return '';
  }
  return Array.isArray(input) ? input[0] : input;
};
