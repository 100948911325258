import IUser from '@/models/users/IUser';
import DjangoGet from '@/services/typescript-api-services/src/api/django_service/methods/get';
import DjangoPatch from '@/services/typescript-api-services/src/api/django_service/methods/patch';
import { Environment } from 'environment';

// Models and interfaces
// NOTE: these should be typed as they're expected to be received from the API
// i.e., often snake_case for Django
interface IUsersApiPatchParams {
  first_name?: string;
  last_name?: string;
}

/**
 * USERS API
 */
export class UsersApi {
  name = 'Users API';
  uriApi = Environment.uriApi;
  uriPath = 'users/accounts';
  token: string;

  // API METHODS
  get: DjangoGet<IUser>;
  patch: DjangoPatch<IUser, IUsersApiPatchParams>;

  // CONSTRUCTOR
  constructor(token: string) {
    this.token = token;

    this.get = new DjangoGet<IUser>(this.name, this.uriApi, this.uriPath, this.token);
    this.patch = new DjangoPatch<IUser, IUsersApiPatchParams>(this.name, this.uriApi, this.uriPath, this.token);
  }
}