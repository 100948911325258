import { useEffect } from 'react';
import { useStateManagerContext } from '@/components/react_wrappers/state_manager/context';
import type React from 'react';

/**
 * PROPS
 *
 * @param {React.ReactNode} children - children component
 */
interface IPropsSettingsManager {
  children: React.ReactElement;
}

/**
 * SETTINGS MANAGER
 * This is the main Settings manager for the app.
 * It gets the settings from the API and sets the user and project context.
 */
export function SettingsManager(Props: IPropsSettingsManager): React.ReactElement {
  const { children } = Props;

  // update settings on each load and then every minute
  const stateManagerContext = useStateManagerContext();
  useEffect(() => {
    const updateSettings = () => {
      stateManagerContext.updateStateFunctions.updateProjectSettings();
    };

    updateSettings(); // Initial call to update settings

    // Schedule update every minute
    const intervalId = setInterval(updateSettings, 60000);

    // Clear interval on component unmount
    return () => clearInterval(intervalId);
  }, [stateManagerContext.updateStateFunctions]);

  return children;
}
