import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';
import IUser from '@/models/users/IUser';

const { persistAtom } = recoilPersist();

// TOKEN
const stateKeyToken = 'stateKeyToken';
const stateToken = atom<string | null>({
  key: stateKeyToken,
  default: null,
  effects_UNSTABLE: [persistAtom],
});

// USER
const stateKeyUser = 'stateKeyUser';
const stateUser = atom<IUser | null>({
  key: stateKeyUser,
  default: null,
  effects_UNSTABLE: [persistAtom],
});

export { stateToken, stateUser };
